.post {
    background-color: var(--background-secondary);
    border-radius: 0.5rem;
    margin-bottom: 1rem;
}
.mb05 {
    margin-bottom: 0.5rem;
}

.header {
    padding: 0.75rem;
    display: flex;
    align-items: center;
    word-wrap: anywhere;
}
.header > svg {
    width: 2rem;
    height: 2rem;
}
.header.smallIcon > svg {
    width: 1.4rem;
    height: 1.4rem;
}
.header.pinned {
    background: var(--background-element);
    border-radius: 0.5rem;
}
.header.noPadding {
    padding: 0;
}
.header.dense {
    padding: 0.5rem;
}
.header.clickable {
    cursor: pointer;
}
.header.alignStart {
    align-items: flex-start;
}

.headerText {
    margin-left: 0.5rem;
    flex: 1;
    min-width: 0;
}
.headerTitle, .headerSecondary {
    display: block;
}
.headerTitle:not(.allowOverflow), .headerSecondary:not(.allowOverflow) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.headerFandom {
    font-weight: bold;
    color: var(--text);
    text-decoration: none;
}
.headerSecondary {
    color: var(--text-secondary);
}
.headerAuthor {
    text-decoration: none;
    color: var(--text-secondary);
}
.headerAuthor svg {
    width: 1rem;
    vertical-align: bottom;
    margin-right: 0.25rem;
}
.headerRubric {
    color: var(--text-secondary);
    font-weight: normal;
    text-decoration: none;
}
a.headerAuthor:hover, .headerRubric:hover, a.headerFandom:hover {
    text-decoration: underline;
}
/* dots */
.headerMore {
    margin-left: auto;
    width: 30px;
    padding-left: 10px;
}

.footer {
    /* without this, footer buttons cannot be pressed in chrome on mobile */
    position: relative;

    display: flex;
    align-items: center;
}
.footer > * {
    padding: 0.75rem 0;
}
.footer > :first-child {
    padding-left: 1rem;
}
.footer > :last-child, .expander {
    padding-right: 1rem;
}
.spacer {
    flex-grow: 1;
}
.commentCounter {
    padding-left: 0.5rem;
}
.commentCounter, .expander, .editButton {
    text-decoration: none;
    color: var(--text);
    display: flex;
    cursor: pointer;
    padding-right: 0.5rem;
}
.commentIcon, .expandIcon, .editIcon {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.25rem;
}

.content {
    max-height: 512px;
    overflow: hidden;
    position: relative;
}
.content.expanded {
    max-height: none;
}
.content:not(.expanded)::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2rem;
    bottom: 0;
    left: 0;
    z-index: 100;
    background: linear-gradient(180deg, transparent, var(--background-secondary));
}
.content.padded {
    padding: 0.5rem 0.75rem;
}

.transitionLeave {
    transition: all 200ms ease-in;
}
.transitionLeaveFrom {
    transform: scale(100%);
    opacity: 1;
}
.transitionLeaveTo {
    transform: scale(95%);
    opacity: 0;
}

.postCover {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background: rgba(0,0,0,.75);
    overflow-y: scroll;
}
.postCoverClick {
    width: 770px;
    margin: 1rem 0 0.5rem;
}

.coverClose {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    cursor: pointer;
}
.coverClose svg {
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0.5rem;
}

.postCover .post {
    margin-bottom: 0.5rem;
    width: 770px;
}

.coverTagsOuter {
    background-color: var(--background-secondary);
    margin-bottom: 0.5rem;
    border-radius: 0.5rem;
    display: block;
}
.coverTagsOuter > .coverTags {
    padding: 0.75rem 0.75rem 0.5rem;
    margin-bottom: 0;
}
.coverComments {
    background-color: var(--background-secondary);
    padding: 0.75rem 0.75rem 0.25rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    max-width: 770px;
}

@media screen and (max-width: 770px) {
    .postCover > .coverTagsOuter {
        width: 100%;
    }
    .postCover .post {
        width: 100%;
    }
    .postCoverClick {
        width: 100%;
    }
}
@media screen and (max-width: 1005px) {
    .coverComments {
        padding: 0;
    }
}

.profileStatus {
    text-align: center;
    padding: 0.8rem;
}

/* --- moderation --- */

.modContent {
    padding: 0.5rem 0.75rem;
}
.modContent:last-of-type {
    padding-bottom: 1rem;
}

.modAdminLink {
    color: var(--red);
}
.modUserLink {
    color: var(--blue);
}

.modAdminAccept {
    color: var(--green);
}
.modAdminReject {
    color: var(--red);
}
.modRejectTitle {
    color: var(--text);
}
.modRejectComment {
    color: var(--text-secondary);
    margin-top: 0.25rem;
}

.modImage {
    margin: 0.5rem 0;
    text-align: center;
}
.modImage img {
    max-height: 512px;
    max-width: 100%;
}

/* --- post filters --- */
.filtersHeader {
    display: flex;
}

.filtersButton {
    margin-left: auto;
    display: flex;
    cursor: pointer;
    padding: 0.75rem;
}
.filtersButton > svg {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.25rem;
}

.filtersContent {
    transition: all 0.5s ease;
    opacity: 0;
    overflow: hidden;
    transform-origin: top;
    max-height: 0;
}
.filtersExpanded > .filtersContent {
    opacity: 1;
    max-height: 165px;
}

.reset {
    color: var(--text);
    text-decoration: none;
}
.sponsorStar {
    margin-left: 0.25rem;
    color: rgb(236,72,153);
}
.sponsorStar > svg {
    vertical-align: bottom;
    width: 1rem;
    height: 1rem;
}

.eventTop {
    padding: 0.75rem;
    border-bottom: 1px dotted var(--background-element);
    color: var(--text-secondary);
}
.eventContent {
    padding: 0.25rem 0;
}
