.avatarWrap {
    position: relative;
    height: 40px;
    width: 40px;
    display: inline-block;
}
.avatarWrap.small {
    height: 30px;
    width: 30px;
}
.avatar, .avatar img {
    border-radius: 8px;
}
.avatarBadge {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 1rem;
    height: 1rem;
    font-size: 0.75rem;
    font-weight: bold;
    color: var(--text);
    background: var(--background);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.avatarBadge.long {
    font-size: 0.6rem;
}
.avatarBadge.online {
    background: var(--green-very-muted);
    color: var(--text);
}
.avatarBadge.sponsor {
    background: rgb(236,72,153);
    background: linear-gradient(135deg, rgba(236,72,153,1) 0%, rgba(184,128,20,1) 87%);
    color: white;
}
