.spinner {
    animation: 0.5s linear infinite spin;
    fill: var(--text);
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
