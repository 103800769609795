.button {
    padding: 0.5rem 0.8rem;
    background-color: var(--background-element);
    color: var(--text);
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
    font-size: 1.1rem;
}

a.button {
    text-decoration: none;
}

.button.noBackground {
    background-color: initial;
}
.button.secondary {
    background-color: var(--background-secondary);
}

.button:hover:not(:disabled), .button:focus:not(:disabled) {
    background-color: var(--background);
}
.button:disabled {
    cursor: default;
}

.button.fullWidth {
    display: block;
    width: 100%;
}

.button > svg {
    margin-right: 0.2rem;
    width: 1rem;
    height: 1rem;
}
