.header {
    position: fixed;
    height: 50px;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    background-color: var(--background-secondary);
    width: 100%;
    z-index: 1000;
    filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
}

.overlay {
    filter: none;
    display: block;
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 50px;
    background: rgba(0,0,0,.5);
    z-index: 900;
}

.h1link {
    text-decoration: none;
    font-size: 1.5rem;
    color: var(--text);
    padding-right: 1rem;
}
.releaseCycle {
    font-weight: normal;
    font-size: 1rem;
    font-style: italic;
    font-family: var(--monospace-font);
}

.navLink {
    color: var(--text-secondary);
    text-decoration: none;
    padding-right: 0.5rem;
    cursor: pointer;
}
.navLink:hover, .navLink:focus {
    color: var(--text);
}
.navLink > svg {
    width: 1rem;
    height: 1rem;
    margin-right: 0.2rem;
    vertical-align: bottom;
}

.spacer {
    flex-grow: 1;
}

.account {
    display: flex;
    align-items: center;
    font-weight: bold;
    color: var(--text);
    text-decoration: none;
    cursor: pointer;
}
.accountAvatar {
    margin-right: 0.5rem;
}

.themeButton {
    width: 1.2rem;
    margin-right: 0.5rem;
    cursor: pointer;
}

.popoverRoot {
    position: relative;
    height: 40px;
    display: flex;
}
.transitionRoot {
    position: absolute;
    top: 45px;
    right: -1rem;
    width: 50vw;
    transform-origin: top right;
    z-index: 1000;
}

.menu {
    display: block;
    z-index: 1000;
    padding: 1rem;
    background-color: var(--background-secondary);
    border-bottom-left-radius: 1rem;
    border-bottom: 2px solid var(--background-element);
    border-left: 2px solid var(--background-element);
}

.buttonReset {
    background-color: transparent;
    border: none;
    padding: 0;
    text-align: initial;
}

.menu .account {
    margin-bottom: 1rem;
}
.menu > .navLink {
    display: block;
    margin: 0.5rem 0;
}
.menu > .quest {
    margin-bottom: 1rem;
}

.questTitle {
    margin-bottom: 0.5rem;
    line-height: 1rem;
}
.questProgress {
    color: var(--text-secondary);
}

.menuDivider {
    width: 100%;
    height: 2px;
    background: var(--background-element);
    border-radius: 1px;
}

@media screen and (max-width: 512px) {
    .header {
        padding: 0 0.5rem;
    }
    .account:not(.accountFull) .accountAvatar {
        margin-right: 0;
    }
    .account:not(.accountFull) .accountText {
        display: none;
    }
    .transitionRoot {
        width: 100vw;
        right: -0.5rem;
    }
    .menu {
        border: none;
        border-radius: 0;
    }
}
@media screen and (min-width: 1024px) {
    .transitionRoot {
        width: 33vw;
    }
}
