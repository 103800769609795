.karmaCounter {
    font-weight: bold;
    position: relative;
}
.mr {
    margin-right: 0.5rem;
}

.cof {
    position: absolute;
    bottom: -0.8rem;
    left: 50%;
    transform: translateX(-50%);
    font-weight: normal;
    color: var(--text-secondary);
    font-size: 0.8rem;
    width: 100%;
    min-width: 2rem;
    text-align: center;
}

.karma {
    display: flex;
    align-items: center;
}
.karma.vertical {
    flex-direction: column-reverse;
}
.karmaButton {
    width: 2rem;
    height: 2rem;
    padding: 0.2rem;
    cursor: pointer;
}
/* svg - loading spinner */
.karmaVoted .karmaButton, .karmaButton.loading {
    cursor: default;
    color: var(--text-secondary);
}
.karmaPositive, .karmaVoted .karmaButton.karmaPositive {
    color: var(--green);
    fill: var(--green);
}
.karmaNegative, .karmaVoted .karmaButton.karmaNegative {
    color: var(--red);
    fill: var(--red);
}

.karma.small .karmaButton {
    width: 1.5rem;
    height: 1.5rem;
    padding: 0;
}
.karma.small .karmaButton:first-child {
    margin-left: -5px;
}
