.text {
    display: inline-block;
    width: 100%; height: 1rem;
    border-radius: 0.25rem;
    background: var(--background-element);
}
.box {
    border-radius: 0.25rem;
    background: var(--background-element);
}
