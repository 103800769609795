.wrapper {
    position: relative;
    display: inline-block;
    text-align: left;
}

.button {
    cursor: pointer;
}

.transitionEnter {
    transition: all 100ms ease-out;
}
.transitionEnterFrom {
    transform: scale(95%);
    opacity: 0;
}
.transitionEnterTo {
    transform: scale(100%);
    opacity: 1;
}

.transitionLeave {
    transition: all 75ms ease-in;
}
.transitionLeaveFrom {
    transform: scale(100%);
    opacity: 1;
}
.transitionLeaveTo {
    transform: scale(95%);
    opacity: 0;
}

.items {
    position: absolute;
    right: 0;
    width: 14rem;
    margin-top: 0.5rem;
    transform-origin: top right;
    background-color: var(--background-element);
    border-radius: 0.5rem;
    z-index: 999;
    padding: 0.25rem 0;
}
.itemsSection {
    padding: 0.2rem 0;
}
.itemsSection ~ .itemsSection {
    border-top: 1px solid var(--background-secondary);
}

.item {
    padding: 0.5rem 0.5rem;
    cursor: pointer;
}
.item.active {
    background-color: var(--background);
}

.moderator {
    background-color: var(--blue);
    color: white;
}
.moderator.active {
    background-color: var(--blue-alt);
}
