* {
  box-sizing: border-box;
  margin: 0;
}

html, body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}
.modal-open {
  overflow: hidden;
}

a {
  color: var(--primary);
}

.container {
  max-width: 1005px;
  padding: 0 1rem;
  margin: 1rem auto 0;
}

#modal-root {
  position: relative;
  z-index: 10000;
}
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background: rgba(0,0,0,.5);
}
.modal img {
  padding: 1rem;
  max-width: 100%;
  max-height: 100%;
}

#nprogress .bar {
  background-color: #ff6c01 !important;
}
#nprogress .peg {
  box-shadow: 0 0 10px #ff6c01, 0 0 5px #ff6c01 !important;
}
#nprogress .spinner-icon {
  border-top-color: #ff6c01 !important;
  border-left-color: #ff6c01 !important;
}
